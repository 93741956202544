<template>
  <nav>
    <v-app-bar>
      <!-- <v-app-bar-nav-icon @click="drawer = !drawer"></v-app-bar-nav-icon> -->

      <v-img
        src="@/assets/AN-logo.svg"
        max-height="60"
        max-width="200"
        contain
        min-height="60"
      >
        <template v-slot:error>
          <v-app-bar-title class="grey--text">
            <span class="font-weight-light text-uppercase">Audio</span>
            <span class="text-uppercase">NOLA</span></v-app-bar-title
          >
        </template>
      </v-img>

      <v-tabs>
        <v-tab v-for="link in links" :key="link.route" :to="link.route">
          <!-- <v-icon>{{ link.icon }}</v-icon> -->
          {{ link.text }}
        </v-tab>
      </v-tabs>
      <v-spacer></v-spacer>
    </v-app-bar>
  </nav>
</template>

<script>
// import { ref } from "vue";

export default {
  name: "NavBar",
  setup() {
    // const user = computed(() => {
    //   return store.state.user;
    // });
    // const signout = async () => {
    //   await store.dispatch("logout").then(() => {
    //     console.log("signed out");
    //     // this.$router.push({name: 'Home'})
    //   });
    // };
    const links = [
      {
        icon: "mdi-home",
        text: "Home",
        route: { name: "home" },
      },
      {
        icon: "mdi-icon",
        text: "About",
        route: { name: "about" },
      },
      {
        icon: "mdi-icon",
        text: "Events",
        route: { name: "events" },
      },
      {
        icon: "mdi-icon",
        text: "contact",
        route: { name: "contact" },
      },
    ];

    return { links };
  },
};
</script>
