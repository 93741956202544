<template>
  <v-container class="contact">
    <v-row>
      <v-col>
        <span class="text-h3">Contact</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <p>
          Audio NOLA, LLC<br />
          1109 Helios Avenue<br />
          Metairie, LA 70005
        </p>
        <p>Phone: 504-475-4310</p>
      </v-col>
      <v-col cols="12" md="6">
        <v-list>
          <!-- <v-list-subheader
            ><span class="text-h5">Social:</span></v-list-subheader
          > -->

          <v-list-item prepend-icon="mdi-email"
            ><a href="mailto:info@audionola.com"
              >info@audionola.com</a
            ></v-list-item
          >
          <v-list-item prepend-icon="mdi-facebook"
            ><a href="https://www.facebook.com/audionola"
              >Facebook</a
            ></v-list-item
          >
          <v-list-item prepend-icon="mdi-instagram"
            ><a href="https://www.instagram.com/audio_nola"
              >Instagram</a
            ></v-list-item
          >
        </v-list>
      </v-col>
    </v-row>
  </v-container>
</template>
