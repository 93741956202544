<template>
  <v-parallax height="1080" src="@/assets/soundwavesbg1.jpg">
    <v-container class="fill-height justify-center align-center">
      <v-row height="1000" align="center">
        <v-col cols="12">
          <v-img
            src="@/assets/AN-logo.svg"
            max-width="1000"
            min-width="50%"
            class="mx-auto"
          ></v-img>
        </v-col>
        <v-col>
          <div class="fill-height justify-center text-center align-center">
            <h1
              class="text-h4 font-weight-bold mb-4 logo-light-blue text-center mt-0"
            >
              SOUND • LIGHTING • EVENT PRODUCTION
            </h1>

            <h4 class="subheading text-center">
              <v-btn
                icon="mdi-facebook"
                href="https://www.facebook.com/audionola"
              ></v-btn>
              &nbsp;&nbsp;
              <v-btn
                icon="mdi-instagram"
                href="https://www.instagram.com/audio_nola"
              ></v-btn>
            </h4>
          </div>
        </v-col>
      </v-row>
    </v-container>
  </v-parallax>
</template>

<script></script>

<style>
.logo-light-blue {
  color: #b7caff;
}
</style>
