<template>
  <v-app>
    <v-main>
      <NavBar />
      <v-container max-width="1200" class="mx-auto">
        <router-view />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import NavBar from "./components/NavBar.vue";

export default {
  name: "App",
  components: { NavBar },
  setup() {
    //
  },
};
</script>
