<template>
  <v-container class="events">
    <v-row>
      <v-col>
        <span class="text-h5">We service events of every size. Including:</span>
      </v-col>
    </v-row>

    <v-row>
      <v-col v-for="card in cards" :key="card.title">
        <v-card
          cols="12"
          lg="4"
          xl="3"
          md="2"
          class="mx-auto fill-height"
          width="250"
        >
          <v-img
            v-show="card.img"
            :src="card.img"
            max-height="250px"
            cover
          ></v-img>
          <v-card-title> {{ card.title }} </v-card-title>
          <v-card-text>{{ card.body }}</v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup>
import { ref } from "vue";

const cards = ref([
  {
    expanded: false,
    title: "Ceremonies",
    img: "/img/Ceremony.jpg",
    body: "Wedding ceremony sound to ensure no one misses the “I Dos.”",
  },
  {
    expanded: false,
    title: "Receptions",
    img: "/img/Weddings1.jpg",
  },
  {
    expanded: false,
    title: "Auctions",
    img: "/img/LightingGala.jpg",
  },
  {
    expandable: false,
    expanded: false,
    title: "Event Management",
    img: null,
    body: "We can help you manage all of the technical aspects of your event. From coordinating vendors to establishing lighting and audio design for any type of production, we have you covered. Let us deal with the headaches while you prepare for your event.",
  },
  {
    expanded: false,
    title: "Live Streams",
    img: "/img/LiveStreams1.jpg",
  },
  {
    expanded: false,
    title: "Webinars",
    img: "/img/Webinar.jpg",
  },
  {
    expanded: false,
    title: "Theatre",
    img: "/img/Theatre2.jpg",
  },
  {
    expanded: false,
    title: "Balls",
    img: "/img/Balls.jpg",
  },
  {
    expanded: false,
    title: "Carnival",
    img: "/img/Carnival.jpg",
  },
  {
    expanded: false,
    title: "Concerts",
    img: "/img/Concert.jpg",
  },
  {
    expanded: false,
    title: "Fundraisers",
    img: "/img/Fundraisers.jpg",
  },
  {
    expanded: false,
    title: "Corporate Parties",
    img: "/img/Corp.jpg",
  },
  {
    expanded: false,
    title: "Uplighting",
    img: "/img/Lighting-Col.jpg",
  },
  {
    expanded: false,
    title: "Galas",
    img: "/img/Gala2.jpg",
  },
  {
    expanded: false,
    title: "Press Conferences",
    img: "/img/PressConf.jpg",
  },
  {
    expanded: false,
    title: "Education",
    img: "/img/Education.jpg",
  },
  {
    expanded: false,
    title: "DJ's",
    img: "/img/DJ.jpg",
  },
  {
    expanded: false,
    title: "Bands",
    img: "/img/Bands1.jpg",
  },
  {
    expanded: false,
    title: "Fairs and Festivals",
    img: "/img/FairsFests.jpg",
  },
  {
    expanded: false,
    title: "Dance Recitals",
    img: "/img/Dance.jpg",
  },
]);
</script>
