// Styles
import "@mdi/font/css/materialdesignicons.css";
import "vuetify/styles";

// Vuetify
import { createVuetify } from "vuetify";

import colors from "vuetify/lib/util/colors";

export default createVuetify(
  {
    theme: {
      themes: {
        light: {
          dark: true,
          colors: {
            primary: colors.indigo.darken4,
            secondary: colors.blue.darken2,
          },
        },
      },
    },
  }
  // https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
);
